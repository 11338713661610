// Provider imports 'rollbar'
import { queryClient } from '../services/queryClient';
import '../styles/globals.css';
import { Provider } from '@rollbar/react';
// Provider imports 'rollbar'
import { QueryClientProvider } from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const isProduction = process.env.NEXT_PUBLIC_ENV === 'production';

// if (typeof window !== 'undefined') {
//   if (process.env.NEXT_PUBLIC_ENV === 'production') {
//     ReactGA.initialize(`${process.env.NEXT_PUBLIC_GA_KEY}`);
//     ReactGA.send('pageview');
//   }
// }

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug();
    },
  });
}

const rollbarConfig = {
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_KEY,
  environment: isProduction ? 'production' : 'testenv',
};
export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <div className="bg-background">
      <GoogleAnalytics trackPageViews />
      <PostHogProvider client={posthog}>
        <Provider config={rollbarConfig}>
          <Head>
            <title>DeFi Returns - Pods</title>
          </Head>
          <QueryClientProvider client={queryClient}>
            <Component {...pageProps} />
            <ToastContainer />
          </QueryClientProvider>
        </Provider>
      </PostHogProvider>
    </div>
  );
}
